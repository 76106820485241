<script setup>
import { extractDomainFromUrl } from "~/utils/urls.js";

const props = defineProps({
  url: {
    type: String,
    required: true,
  },
  dynamicClass: {},
});

const domain = computed(() => {
  let extractedDomain = extractDomainFromUrl(props.url);
  if (extractedDomain === "youtu.be") {
    extractedDomain = "youtube.com";
  }
  return extractedDomain;
});

const faviconUrl = computed(
  () =>
    `https://t3.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=https://${domain.value}&size=64`
);
</script>

<template>
  <NuxtImg
    :key="`${url}-icon`"
    :src="faviconUrl"
    :height="10"
    :weight="10"
    class="rounded-2xl h-7 w-7 pointer-events-none"
    :class="dynamicClass"
  />
</template>

<style scoped></style>
